import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment, InsightsFragment } from "components/fragments"
import tw, { styled } from "twin.macro"
import HubspotForm from "react-hubspot-form"

import Layout from "components/layout"
import SEO from "components/seo"
import { getSharePreviewUrls } from "utils/share-preview"
import LightSlantedSection from "components/sections/light-slant"
import VideoModal from "components/sections/video-modal"
import ContentValuation from "components/sections/content-valuation-ad"
import StyledSlantedSection from "components/sections/styled-slant"
import ProductCards from "components/product-cards"
import ArticleShowcase from "components/sections/article-showcase"
import SpecialReports from "components/sections/special-reports"
import ManualModal from "components/manual-modal"
import MailIcon from "images/icons/email-black.svg"
import { getItem, setItem } from "utils/storage"
import BasicBackgroundSection from "components/basic-background-section"
import BgSpecialReports from "images/bg/special-reports.jpg"
import { useMounted } from "utils/use-mounted"

const Styles = styled.div`
  .header {
    ${tw`pt-24 m:pt-28 l:pt-32 text-white mx-auto m:max-w-m l:max-w-l`}
  }
`

const LargeTitle = styled.h2`
  ${tw`py-2 w-full font-display font-bold text-white text-center`}

  font-size: 30px;
  line-height: 35px;

  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const Paragraph = styled.div`
  ${tw`font-body text-white text-center mx-auto`}

  font-size: 15px;
  line-height: 25px;

  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: 1200px) {
    max-width: 800px;
  }
`

const Hubspot = styled.div`
  ${tw`overflow-hidden`}

  .hs-form {
    ${tw`text-black w-full m:w-11/12 mx-auto`}
    font-size: 12px;

    legend,
    label {
      margin: 0;
    }
  }

  .hs-field-desc,
  .hs-form label {
    ${tw`text-base text-center`}

    &.hs-error-msg {
      ${tw`text-neonred text-xs m:text-sm`}
    }
  }

  .hs-form .hs_error_rollup label {
    ${tw`hidden`}
  }

  .hs-form-booleancheckbox {
    ${tw`pt-4 pb-3 border-t border-b border-priority-darkP2 text-xs m:text-sm`}

    label {
      ${tw`flex flex-row justify-center`}

      span {
        ${tw`text-xs m:text-sm`}
      }
    }
  }

  .hs-email {
    ${tw`mt-5 m:mt-8 w-full m:w-7/12 mx-auto`}

    label {
      ${tw`hidden h-0 m-0 p-0`}

      &.hs-error-msg {
        ${tw`block h-auto`}
      }
    }
  }

  .hs-form .field {
    padding: 0;
    padding-top: 5px;
  }

  .hs-form .hs-button {
    ${tw`w-1/3 m:w-1/4 bg-yellow rounded p-3 px-4 uppercase font-display text-white text-sm flex-shrink block mx-auto`}
    background: linear-gradient(180deg, #3498DB 0%, #006AA9 100%);
    box-shadow: none;
    border: none;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    text-shadow: none;
    height: auto;
    width: auto;
  }

  .hs-email .input {
    position: relative;

    &:after {
      content: "";
      background-image: url(${MailIcon});
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 11px;
      left: 12px;
      height: 20px;
      width: 20px;
    }
  }

  .hs-email .hs-input {
    ${tw`bg-grey-95`}
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 #e6e6e6;
    border: solid 1px #ffffff;
    padding-left: 40px;
    height: 44px;

    &:focus {
      background-color: #ffffff;
    }
  }

  .actions {
    ${tw`flex flex-col items-center m:items-start mt-6`}
  }
`

const InfoSection = ({ children, id, className, contentBlock }) => (
  <div className={className} id={id}>
    <LargeTitle style={{ textAlign: "center" }}>
      {contentBlock.title}
    </LargeTitle>
    <Paragraph className="pt-6 pb-16">
      <div
        className="text-yellow font-body text-sm m:text-base l:text-xl text-center"
        dangerouslySetInnerHTML={{
          __html: contentBlock.headerContent?.html,
        }}
      />
    </Paragraph>
    {children}
  </div>
)

const FormStyles = styled.div`
  ${tw`w-full text-priority-darkP1 text-center px-4`}

  max-width: 800px;
`

const RegistrationForm = ({ close }) => {
  return (
    <FormStyles>
      <h3 className="text-xl m:text-3xl font-bold">
        Enter your email address for instant access
      </h3>
      <p className="text-priority-darkP2 text-xs m:text-sm mt-6 m:mt-10">
        You are one step away from gaining{" "}
        <span className="font-extrabold">
          instant access to all of Parrot Analytics' best reports
        </span>
        . Please enter your email address to get access now and you will also
        receive regular email updates on global audience demand trends and
        industry dynamics. You can unsubscribe at any time.
      </p>
      <Hubspot className="mt-6 m:mt-10">
        <HubspotForm
          portalId={process.env.GATSBY_HUBSPOT_ID}
          formId={process.env.GATSBY_HUBSPOT_SPECIAL_REPORTS_FORM}
          loading={<div>Loading...</div>}
          formInstanceId="reports-newsletter-form"
          onBeforeFormInit={(ctx) => {
            setTimeout(() => {
              const emailField = document.querySelector(
                `${ctx.target} input[name=email]`
              )

              if (emailField?.value) {
                close()
              }
            }, 200)
          }}
        />
      </Hubspot>
    </FormStyles>
  )
}

const Reports = ({ data }) => {
  const [modal, setModal] = useState(true)

  const isMounted = useMounted()

  const { gcms } = data
  const {
    page,
    reportsSerie,
    perspectiveArticles,
    demandBlocks,
    exploreBlocks,
  } = gcms

  const reports = reportsSerie?.reports || []

  const demandBlock = demandBlocks[0]
  const exploreBlock = exploreBlocks[0]

  const bgStack = [
    `linear-gradient(180deg, rgba(40, 16, 64, 0.8) 0%, rgba(30, 12, 55, 0.8) 4.89%, rgba(9, 0, 23, 0.8) 10.31%, rgba(9, 0, 23, 0.8) 100%)`,
    `url(${BgSpecialReports})`,
  ]

  useEffect(() => {
    if (modal && getItem("reports-email-entered")) {
      setModal(false)
    }
  }, [modal])

  useEffect(() => {
    window.addEventListener("message", submitHandler)
    return () => {
      window.removeEventListener("message", submitHandler)
    }
  }, [])

  function submitHandler(event) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      if (event.data.id === process.env.GATSBY_HUBSPOT_SPECIAL_REPORTS_FORM) {
        setTimeout(() => {
          closeModal()
        }, 500)
      }
    }
  }

  function closeModal() {
    setItem("reports-email-entered", true)
    setModal(false)
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      {isMounted && (
        <ManualModal
          open={modal}
          onClose={() => {}}
          className="reports"
          zIndex={40}
          blockScroll={false}
        >
          <div className="flex flex-column items-center justify-center h-full">
            <RegistrationForm close={() => closeModal()} />
          </div>
        </ManualModal>
      )}

      <Styles className={modal ? "filter blur-sm" : ""}>
        <BasicBackgroundSection
          bgStack={bgStack}
          color="#1E1E1E"
          className="body"
          repeat="repeat-y"
          size="100% auto"
          bgPosition="center"
        >
          <div className="header">
            <LargeTitle style={{ textAlign: "center" }}>
              {page.headerTitle}
            </LargeTitle>
            <Paragraph
              className="pt-2 px-2"
              dangerouslySetInnerHTML={{
                __html: page.headerContent?.html,
              }}
            />
          </div>

          <div className="content">
            <SpecialReports reports={reports} />
          </div>

          <LightSlantedSection>
            {perspectiveArticles?.length > 0 && (
              <div>
                <ArticleShowcase
                  articles={perspectiveArticles}
                  title="Parrot Perspective"
                  wide={true}
                />
              </div>
            )}
          </LightSlantedSection>

          <StyledSlantedSection className="z-10 pt-16 l:pt-32 pb-4 m:pb-0 content-valuation reports">
            <ContentValuation />
          </StyledSlantedSection>

          {demandBlock && (
            <InfoSection
              className="px-2 pt-24 mx-auto m:max-w-m l:max-w-l"
              contentBlock={demandBlock}
            >
              <VideoModal
                id="why-demand-video"
                className="relative"
                caption={"Why demand?"}
                data={demandBlock.extraData}
              />
            </InfoSection>
          )}

          {exploreBlock && (
            <InfoSection
              id="products"
              className="px-2 pt-24 m:pt-36 pb-40 mx-auto m:max-w-m l:max-w-l"
              contentBlock={exploreBlock}
            >
              <ProductCards
                cardKeys={[
                  "content-valuation",
                  "demand360",
                  "ip-topic-demand",
                  "affinity-solutions",
                  "supply-metadata",
                  "demand360-lite",
                ]}
              />
            </InfoSection>
          )}
        </BasicBackgroundSection>
      </Styles>
    </Layout>
  )
}

export default Reports

export const query = graphql`
  query SpecialReportArticles {
    gcms {
      page(where: { path: "/reports" }) {
        ...PageData
      }
      reportsSerie: insightsArticleSeries(
        where: { id: "cl7z3rszj8hag0cmzsxa2yny0" }
      ) {
        reports: insightsArticles {
          id
          title
          shortDescription {
            html
          }
          featuredImage {
            url(
              transformation: {
                image: { resize: { width: 780, fit: clip } }
                document: { output: { format: webp } }
              }
            )
          }
          files(first: 1, where: { mimeType: "application/pdf" }) {
            id
            fileName
            url
            mimeType
          }
          slug
        }
      }
      perspectiveArticles: insightsArticles(
        where: { insightsCategories_some: { slug: "parrot-perspective" } }
        orderBy: publishingDate_DESC
      ) {
        ...InsightsData
      }
      demandBlocks: contentBlocks(
        where: { identifier: "demand-drives-monetization-section" }
      ) {
        title
        headerContent {
          html
        }
        extraData
      }
      exploreBlocks: contentBlocks(
        where: { identifier: "explore-our-products-section" }
      ) {
        title
        headerContent {
          html
        }
      }
    }
  }
`

import React from "react"
import tw, { styled } from "twin.macro"

import ProductDotsBg from "images/bg/product-dots-dark.jpg"
import ContentValuationBg from "images/bg/content-valuation-ad.jpg"

const StyledSlant = styled.div`
  ${tw`-mt-16 relative`}

  &::before, &::after {
    ${tw`absolute w-full h-full top-0 left-0 content`}

    clip-path: polygon(0% 10px, 100% 0%, 100% calc(100% - 10px), 0% 100%);
    @media (min-width: 640px) {
      clip-path: polygon(0% 45px, 100% 0%, 100% calc(100% - 45px), 0% 100%);
    }
  }

  &.demand360-lite,
  &.demand360-lite-newsroom {
    &::before {
      ${tw`bg-black`}

      background: linear-gradient(
        180deg,
        rgba(5, 0, 11, 0.8) 3.62%,
        rgba(107, 29, 199, 0.8) 97.17%),
        url(${ProductDotsBg}
      );
      background-size: 100% auto;
      background-repeat: repeat-y;
    }
  }

  &.demand360-lite {
    &::before,
    &::after {
      clip-path: polygon(0% 10px, 100% 0%, 100% 100%, 0% 100%);
      @media (min-width: 640px) {
        clip-path: polygon(0% 45px, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  &.content-valuation {
    ${tw`mt-40`}

    &::before {
      ${tw`bg-black`}

      background: linear-gradient(
          190.39deg,
          rgba(0, 0, 0, 0.79) 29.99%,
          rgba(0, 0, 0, 0.19) 66.85%
        ),
        url(${ContentValuationBg}),
        linear-gradient(275.97deg, #0f8541 7.23%, #015f29 100%);

      background-blend-mode: normal, soft-light, normal;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &::before,
    &::after {
      clip-path: polygon(0% 10px, 100% 0%, 100% 100%, 0% 100%);
      @media (min-width: 640px) {
        clip-path: polygon(0% 45px, 100% 0%, 100% 100%, 0% 100%);
      }
    }

    &.reports {
      ${tw`mt-0`}
    }
  }

  & > div {
    ${tw`relative`}

    z-index: 1;
  }

  .top-line {
    ${tw`absolute bg-springgreen-light w-full top-0 left-0`}

    height: 10px;
    clip-path: polygon(0% 9px, 100% 0%, 100% calc(100% - 9px), 0% 100%);

    @media (min-width: 640px) {
      height: 45px;
      clip-path: polygon(0% 44px, 100% 0%, 100% calc(100% - 44px), 0% 100%);
    }
  }
`
const StyledSlantedSection = ({ children, ...rest }) => (
  <StyledSlant {...rest}>
    <div className="top-line" />
    <div>{children}</div>
  </StyledSlant>
)

export default StyledSlantedSection

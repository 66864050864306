import React, { useState, useRef } from "react"
import tw, { styled } from "twin.macro"
import { useMediaQuery } from "react-responsive"
import { range, difference } from "lodash"

import Button from "components/button"
import Pdf from "images/icons/da-pdf.svg"
import ManualModal from "components/manual-modal"
import { useMounted } from "utils/use-mounted"

const Styles = styled.div`
  ${tw`flex flex-col mx-auto m:max-w-m l:max-w-l space-y-5 pt-24 l:pt-32 pb-44 l:pb-52 px-2 m:px-0`}

  .card {
    ${tw`flex flex-col m:flex-row`}

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;

    .hero {
      ${tw`w-full flex-shrink-0`}

      height: 220px;

      @media (min-width: 640px) {
        width: 390px;
      }

      img {
        ${tw`w-full h-full object-cover cursor-pointer`}

        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        @media (min-width: 640px) {
          border-top-right-radius: 0px;
          border-bottom-left-radius: 5px;
        }
      }
    }

    .content {
      ${tw`p-5 flex flex-col justify-between space-y-3`}

      .info {
        min-height: 180px;

        ${tw`overflow-hidden`}

        @media (min-width: 640px) {
          min-height: 132px;
        }

        h5 {
          ${tw`text-white text-lg l:text-xl font-display line-clamp-3 m:line-clamp-2 cursor-pointer`}
        }

        .description {
          ${tw`mt-2 text-priority-darkP2 text-sm l:text-base line-clamp-4 m:line-clamp-3`}
        }
      }
    }

    .action {
      &.button {
        button {
          ${tw`py-2 px-4 text-xs font-bold`}
        }

        .button-content {
          ${tw`flex flex-row items-center justify-center`}

          img {
            ${tw`mr-3`}
          }

          &.pdf {
            img {
              height: 16px;
            }
          }
        }
      }
    }
  }
`

const SpecialReportCard = ({ report, isDesktop, load, setLoaded, index }) => {
  const [modal, setModal] = useState(false)

  const isMounted = useMounted()

  const pdfLinkRef = useRef()

  const pdfUrl = report.files?.[0]?.url

  function openPdf() {
    if (!pdfUrl) {
      return
    } else if (!isDesktop) {
      // To avoid limitations when opening pdf files in an iframe on iOS
      pdfLinkRef.current?.click()
    } else {
      setModal(true)
    }
  }

  function onLoad() {
    setLoaded()
  }

  return (
    <div className="card" onClick={() => openPdf()}>
      <div className="hero">
        <img src={report.featuredImage?.url} alt="" />
      </div>
      <div className="content">
        <div className="info">
          <h5>
            {index + 1}.&nbsp;{report.title}
          </h5>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: report.shortDescription?.html,
            }}
          />
        </div>
        {pdfUrl && (
          <div className="action button">
            {isMounted && (
              <ManualModal
                open={modal}
                onClose={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setModal(false)
                }}
              >
                <div className="video-player h-full w-full m:px-16">
                  {((load && isDesktop) || modal) && (
                    <iframe
                      src={pdfUrl}
                      className={`w-full h-full ${!modal ? "hidden" : ""}`}
                      onLoad={onLoad}
                      onError={onLoad}
                    />
                  )}
                </div>
              </ManualModal>
            )}
            <a
              href={pdfUrl}
              rel="noopener noreferrer"
              target="_blank"
              ref={pdfLinkRef}
              className="hidden"
            >
              Open pdf on new tab
            </a>
            <Button className="red">
              <div className="button-content pdf">
                <img src={Pdf} alt="" />
                <span className="mt-px leading-none">Open PDF</span>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const SpecialReports = ({ reports }) => {
  const [pending, setPending] = useState(range(reports.length))
  const isDesktop = useMediaQuery({ minWidth: 1200 })

  function removePending(index) {
    setPending(difference(pending, [index]))
  }

  return (
    <Styles>
      {reports.map((report, i) => (
        <SpecialReportCard
          key={report.id}
          report={report}
          isDesktop={isDesktop}
          load={!pending.includes(i) || pending[0] === i}
          setLoaded={() => removePending(i)}
          index={i}
        />
      ))}
    </Styles>
  )
}

export default SpecialReports

import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Button from "components/button"
import { brandBlock } from "utils/brand"

export const ContentValuationStyles = styled.div`
  ${tw`flex flex-col items-center w-full l:w-4/5 mx-auto m:max-w-m l:max-w-l text-white overflow-hidden`}

  .logo {
    width: 351px;
  }

  h1 {
    ${tw`font-bold text-center`}

    font-size: 27px;
    line-height: 40px;

    @media (min-width: 640px) {
      ${tw`font-extrabold`}

      font-size: 60px;
      line-height: 75px;
    }
  }

  p {
    ${tw`font-normal text-center mt-4`}

    font-size: 22px;
    line-height: 33px;
    max-width: 900px;
  }

  .hero {
    height: 360px;
    margin-bottom: -70px;

    @media (min-width: 640px) {
      height: 450px;
      margin-bottom: -80px;
    }

    @media (min-width: 1200px) {
      height: 640px;
      margin-bottom: -130px;
    }
  }

  .contact-us button {
    ${tw`text-springgreen-light`}
  }
`

const ContentValuation = () => {
  const query = graphql`
    query ContentValuationAd {
      gcms {
        contentBlocks(where: { identifier: "content-valuation-ad" }) {
          title
          headerContent {
            html
          }
          linkUrl
          callToAction
          featuredImage {
            url
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const contentAd = brandBlock(data.gcms.contentBlocks[0])

  if (!contentAd) {
    return null
  }

  return (
    <ContentValuationStyles>
      <div className="mx-4 l:mx-0 flex flex-col items-center">
        <h1 className="mt-4">{contentAd.title}</h1>
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: contentAd.headerContent.html,
          }}
        />
        <div className="pt-4 m:pt-0 flex flex-col l:flex-row justify-center items-center w-full l:w-3/4 l:space-x-4 mt-12 l:mt-16">
          <Button to="/contact" className="my-3 w-full secondary contact-us">
            Contact Us
          </Button>
          <Button to={contentAd.linkUrl} className="my-3 w-full">
            {contentAd.callToAction}
          </Button>
        </div>
      </div>
      {contentAd.featuredImage && (
        <Link to={contentAd.linkUrl}>
          <img
            className="hero mt-12 l:mt-16"
            src={contentAd.featuredImage.url}
          />
        </Link>
      )}
    </ContentValuationStyles>
  )
}

export default ContentValuation

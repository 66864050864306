import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import tw, { styled } from "twin.macro"
import { motion, AnimatePresence, useAnimation } from "framer-motion"

import Lightbox from "components/lightbox"
import IconClose from "images/icons/close.inline.svg"

const Content = styled.div`
  ${tw`flex flex-col flex-shrink-0 items-center w-screen h-screen font-body text-white fixed top-0 left-0`}
  overflow: auto;
  padding-bottom: 50px;
  padding-top: 50px;

  &.reports {
    top: 50px;
    height: calc(100vh - 50px);
    padding-top: 0px;
    padding-bottom: 80px;

    @media (min-width: 640px) {
      top: 80px;
      height: calc(100vh - 80px);
    }

    button {
      ${tw`hidden`}
    }
  }

  &.newsroom {
    ${tw`py-0 h-full h-full`}
  }
`

const ManualModal = ({
  children,
  onClose,
  open,
  className = "",
  zIndex = 500,
  blockScroll = true,
  closeButton = true,
}) => {
  const controls = useAnimation()

  useEffect(() => {
    if (!blockScroll) {
      return
    }

    const originalStyle = window.getComputedStyle(
      document.querySelector("html")
    ).overflow

    if (open) {
      document.querySelector("html").style.overflow = "hidden"
    } else {
      document.querySelector("html").style.overflow = originalStyle
    }

    return () => {
      document.querySelector("html").style.overflow = originalStyle
    }
  }, [open])

  useEffect(() => {
    if (open) {
      controls.start("open")
    } else {
      controls.start("close")
    }

    return controls.stop
  }, [open])

  if (typeof document === "undefined") {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      <motion.div
        key="manual-modal"
        variants={{
          close: { opacity: 0, zIndex: -zIndex },
          open: { opacity: 1, zIndex },
        }}
        initial="close"
        animate={controls}
        style={{ position: "relative" }}
      >
        {open && (
          <Lightbox onClick={onClose} opacity={95} className={className} />
        )}
        <Content className={className}>
          {children}
          {closeButton && (
            <button onClick={onClose} className="fixed top-0 right-0 m-6 m:m-8">
              <IconClose />
            </button>
          )}
        </Content>
      </motion.div>
    </AnimatePresence>,
    document.getElementById("modal-container")
  )
}

export default ManualModal
